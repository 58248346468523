import { AxiosPromise } from "axios";
import { get } from "@/application/api";

export interface Conversion {
  id: number;
  createdAt: string;
}

export function conversionShow(
  conversionId: number
): AxiosPromise<{ data: Conversion }> {
  return get(`conversion/${conversionId}`);
}
