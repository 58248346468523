
import Vue from "vue";
import {
  conversionShow,
  Conversion,
} from "@/modules/conversion/api/conversionShow";
import eventBus from "@/application/eventBus";
import dayjs from "@/plugins/dayjs";

export default Vue.extend({
  name: "ConversionShow",
  data: () => ({
    conversion: null as null | Conversion,
  }),
  computed: {
    formattedCreatedAt() {
      if (!this.conversion) return "";
      return dayjs(this.conversion.createdAt).format("LLL");
    },
  },
  created() {
    this.fetchDetails();

    eventBus.$emit("setBreadcrumbs", [
      {
        exact: true,
        to: { name: "home" },
        text: this.$t("global.dashboard"),
      },
      {
        exact: true,
        to: { name: "conversion.index" },
        text: this.$t("conversion.index.subtitle"),
      },
      {
        exact: true,
        to: { name: "conversion.show" },
        text: this.$t("conversion.show.subtitle"),
      },
    ]);
  },
  methods: {
    async fetchDetails() {
      const {
        data: { data: result },
      } = await conversionShow(parseInt(this.$route.params.conversionId));
      this.conversion = result;
    },
    formatJson(jsonString: string) {
      try {
        return JSON.stringify(JSON.parse(jsonString), null, 4);
      } catch (e) {
        return jsonString;
      }
    },
    statusCodeToColor(code: number) {
      if (code >= 200 && code < 300) {
        return "success";
      } else if (code >= 500) {
        return "error";
      }
      return "warning";
    },
  },
});
